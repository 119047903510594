<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="head">
        <div class="title">家庭健康式管理</div>
        <div class="list">
          <div class="point"></div>
          <p>父母健康状况全程关注</p>
        </div>
        <div class="list">
          <div class="point"></div>
          <p>用药提醒 复诊提醒</p>
        </div>
        <div class="list">
          <div class="point"></div>
          <p>家族史疾病防范与治疗</p>
        </div>
      </div>
      <div class="main">
        <div class="card_box">
          <router-link :to="{path: '/my-personal', query: {
            medical_card_no: item.medical_card_no
          }}"
              v-for="(item, idx) in data.members"
              :key="idx"
               >
            <div class="family_card">
              <div class="family_head">
                <div class="img_out">
                  <img
                    :src="get_user_head(item.relationship, item.gender)"
                    width="41"
                    alt=""
                  />
                </div>
                <div>{{ item.relationship_label }}</div>
              </div>

              <div class="family_info">
                <p class="family_name">{{ item.name }}</p>
                <p>健康卡: {{ format_medical_card(item.medical_card_no) }}</p>
              </div>
              <div
                class="family_btn"
                :class="item.default_member ? 'disabled' : ''"
                @click.prevent="set_default(item)"
              >
                {{ item.default_member ? "默认" : "设为默认" }}
              </div>
            </div>
          </router-link>

        </div>
      </div>
    </div>
     <div class="bottom_box">
      <div class="btn" @click="to_health_card">添加家人</div>
    </div>
  </div>
</template>

<script>
import { family_info, set_default_relation } from "./service";
import {format_medical_card} from '../../../utils/format'
import { get_user_head } from '../../../utils/imgUrl'
export default {
  data() {
    return {
      data: {},
      loading: false,
      origin: {}
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    this.get_family_list();
  },
  methods: {
    format_medical_card,
    get_user_head,
    async get_family_list() {
      this.loading = true;
      try {
        const { data } = await family_info();
        this.data = data;
      } finally {
        this.loading = false;
      }
    },
    to_health_card() {
      this.$router.push({
        path: 'health-card'
      })
    },
    async set_default(item) {
      if (item.default_member) return
      this.loading = true;
      try {
        await set_default_relation({medical_card_no:item.medical_card_no});
        this.get_family_list()
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.head {
  height: 176px;
  padding: 20px 25px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  background: url("../../../assets/img/family-bg.png") right center no-repeat
    #000ca8;
  background-size: contain;
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  padding-bottom: 6px;
}

.list {
  display: flex;
  align-items: center;
  & p{
    height: 18px;
  }
}

.point {
  width: 7px;
  height: 7px;
  background: #ffffff;
  border-radius: 50%;
  margin-right: 5px;
}
.main {
  padding: 0 16px;
  position: relative;
}

.card_box {
  position: relative;
  top: -30px;
}

.family_card {
  height: 86px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 16px 14px 16px 24px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.family_head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #ffffff;
  margin-right: 20px;

  & div {
    width: 43px;
    height: 17px;
    background: #ffa700;
    opacity: 1;
    border-radius: 9px;
    text-align: center;
    margin-top: -2px;
  }

  & .img_out {
    width: 45px;
    height: 45px;
    background: #edf5ff;
    border: 2px solid #ffffff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    opacity: 1;
    border-radius: 28px;
    text-align: center;
    margin-top: 0;
  }
}

.family_info {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #707070;
  width: 150px;
}

.family_name {
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #242938;
}

.family_btn {
  min-width: 80px;
  height: 30px;
  background: #ff7823;
  box-shadow: 0px 2px 8px rgba(255, 0, 0, 0.16);
  opacity: 1;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  padding: 0 5px;

  &.disabled {
    background: #dbdbdb;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  }
}

.add_btn {
  position: fixed;
  left: 35px;
  right: 35px;
  bottom: 26px;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: #ffffff;
  height: 40px;
  background: #0088ff;
  opacity: 1;
  border-radius: 6px;
  text-align: center;
}
.bottom_box {
  padding: 0px 13px 24px;
  & .btn {
    width: 100%;
    background: #0088FF;
    border-radius: 9px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
  }
}
</style>
